import * as React from "react";
import styled from "styled-components";
import { Header, TechnologiesUsed, Footer, Image } from "../components";

const TWCProjectStyles = styled.div`
  padding: 17px;
  img {
    width: 100%;
  }
  p,
  ul {
    color: ${(props) => props.theme.text.paragraph};
  }
  .project-description {
    width: 66.6667%;
    margin: 0 auto 80px;
  }
  h1 {
    text-align: center;
  }
  .about-the-project-container {
    width: 60%;
    margin: 80px auto 0;
  }

  .company-img-container {
    display: flex;
    justify-content: center;
    padding: 17px 0;
  }
  .company-img-container img {
    width: 20%;
  }
  .client-description-container {
    width: 100%;
  }
  .client-description-quote {
    border-left: 1px solid #ddd;
    padding-left: 1.618em;
    margin: 0;
  }
  .project-summary-container {
    margin-top: 90px;
  }
  .vacasa-img-container {
    display: flex;
    justify-content: center;
    padding: 30px;
  }
  .project-image-wrapper {
    padding: 17px;
  }
  @media screen and (max-width: 640px) {
    .about-the-project-container {
      display: block;
    }
  }
`;

const gridItems = [
  { link: "", imageName: "react", text: "react" },
  { link: "", imageName: "reduxImage", text: "redux" },
  { link: "", imageName: "nodejs", text: "nodejs" },
  { link: "", imageName: "postgresql", text: "postgreSQL" },
  { link: "", imageName: "amazon-web-services", text: "aws" },
  { link: "", imageName: "bootstrapImage", text: "bootstrap" },
];

const bulletItems = [
  {
    bullet: "UI",
    subBullets: [
      { bullet: "React" },
      { bullet: "Redux" },
      { bullet: "Bootstrap & all CSS" },
      { bullet: "React JSON Schema" },
    ],
  },
  {
    bullet: "API",
    subBullets: [
      { bullet: "NodeJS" },
      { bullet: "Koa" },
      { bullet: "Passport.js" },
    ],
  },
  {
    bullet: "Cloud Architecture - all in Amazon Web Services",
    subBullets: [
      { bullet: "Lambda + API Gateway" },
      {
        bullet: "Redis",
      },
      {
        bullet: "RDS - Postgres",
      },
      { bullet: "Cloudfront + S3" },
    ],
  },
  {
    bullet: "DevOps",
    subBullets: [
      { bullet: "Gitlab CI/CD" },
      {
        bullet: "Amazon Web Services CLI",
      },
    ],
  },
  {
    bullet: "Project Management",
    subBullets: [
      { bullet: "Pivotal Tracker for Agile software management" },
      { bullet: "Gitlab for source code" },
      { bullet: "Zendesk for customer feedback and support" },
      { bullet: "Sentry for automatic bug monitoring" },
      { bullet: "Pingdom for uptime and performance monitoring" },
    ],
  },
];

const TSheetsChromeAppPage = () => (
  <main>
    <Header />
    <TWCProjectStyles>
      <div className="project-description">
        <h1>Boise State University</h1>
        <h1>The Write Class™</h1>
        <p>
          At Boise State University, the First Year Writing Program team had
          developed an algorithm to guide incoming students into the proper
          first-year writing class. We took this system, The Write Class™ (TWC),
          and developed it into a web application. TWC is a unique system that
          uses self-directed placement to assess the student and find the
          perfect class fit. The First Year Writing Program team approached us
          to create this web application that would:
          <ul>
            <li>
              Allow Boise State University to implement the TWC algorithm
              necessary to do student placement via flexible forms with various
              branches.
            </li>
            <li>
              Ensure data security in transit and at rest, and on hardware
              achieving SOC 2 Compliance, all in Amazon Web Services.
            </li>
            <li>
              Integrate with the Boise State University Single Sign On system to
              make using the app easy for students, to make the student records
              have a verified source of student ID, and enable data integrations
              to be put in place.
            </li>
            <li>
              Add a data integration feature that allows the placement results
              to be extracted an uploaded into the school’s student information
              systems.
            </li>
            <li>
              Empower site administrators to change wording without needing to
              contact developers.
            </li>
          </ul>
          With this challenge given to us, we launched the site in the spring of
          2018. The application is a full stack project, with front-end, API,
          and cloud architecture in AWS.
        </p>
        <p>
          <a href="https://www.thewriteclass.org/">
            <span>
              Live Project Link (Boise State University - The Write Class™)
            </span>
          </a>
        </p>
      </div>
      <TechnologiesUsed
        gridItems={gridItems}
        bulletItems={bulletItems}
        columnCount={2}
      />
      <div className="about-the-project-container">
        <h3>Project Role</h3>
        <p>
          Our roles in this project included:
          <ul>
            <li>
              Software Architecture and Programming for both Front-end and API
            </li>
            <li>
              Cloud Architecture and hosting for Amazon Web Services serverless
              stack
            </li>
            <li>
              Integrating with Boise State University&apos;s Single Sign On
              which used OpenID Connect
            </li>
            <li>DevOps integration within GitLab where we do full CI/CD</li>
          </ul>
          We managed day-to-day project management using Pivotal Tracker and
          planning weekly sprints.
        </p>
      </div>
      <div className="about-the-project-container">
        <div className="project-images-container">
          <div className="project-image-wrapper">
            <Image imageName="SingleSignOn" alt="Single Sign On" />
          </div>
          <div className="project-image-wrapper">
            <Image imageName="StudentFlow" alt="Student Flow" />
          </div>
          <div className="project-image-wrapper">
            <Image imageName="ProgressSaved" alt="Progress Saved" />
          </div>
          <div className="project-image-wrapper">
            <Image imageName="AdministratorFlow" alt="Administrator Flow" />
          </div>
          <div className="project-image-wrapper">
            <Image imageName="DataIntegration" alt="Data Integration" />
          </div>
        </div>
        <div className="client-description-container">
          <h3>Feature Summary</h3>
          <p>
            Here are some of the features within the app:
            <ul>
              <li>
                Single Sign On using Boise State University’s existing student
                login system to easily fit within the ecosystem of apps that
                students have to use.
              </li>
              <li>
                Custom, complex form-based algorithm that results in an English
                course placement for the student with optional online follow up
                application for admission into higher level courses.
              </li>
              <li>
                Student progress through the forms is saved to make resuming an
                application easy and reviewing of placement results possible.
              </li>
              <li>
                Administrative panel for searching for student responses and
                viewing all answers.
              </li>
              <li>
                Administrative workflow additions for reviewing
                student-submitted material, including email notifications and
                follow up to students on accept or rejection.
              </li>
              <li>Ability to export all student responses.</li>
              <li>
                Data Integration using an API created to allow university IT
                departments to pull data and push into student info systems.
              </li>
              <li>
                Online editing of the wording so that admins don’t need to
                contact a developer to get simple wording changes completed.
              </li>
            </ul>
          </p>
          <h3>Technology Details</h3>
          <p>
            We chose React as the front-end Javascript framework for this
            project. We knew the project would involve a lot of data from the
            custom forms we would be building, and we identified the open source{" "}
            <a href="https://github.com/mozilla-services/react-jsonschema-form">
              <span>mozilla-services/react-jsonschema-form</span>
            </a>{" "}
            as a solid foundation to build up these custom forms and logic. We
            combined that with using Redux as a central data store to give us
            one-way data flow and to break the complex data transformations into
            isolated pieces to help with design and debugging.
          </p>
          <p>
            We chose NodeJS as the API language as the API wouldn’t be very
            large, but we wanted to be able to have the same libraries available
            for data validation on both the front-end and API. We also wanted a
            language that would work with Amazon Web Services Lambda. Why
            Lambda? Due to the nature of the app, it is used very heavily 2-3
            times per year, during orientation that lasts a few weeks, but
            relatively idle the rest of the time. Lambda allows us to scale
            during those times, but be low cost during times it is not used as
            Lambda charges based on actual usage.
          </p>
          <p>
            Our choice of Amazon Web Services for the cloud architecture and
            hosting was to make it easy to meet the requirement of SOC 2
            Compliant hardware and data encrypted at rest and in transit.
          </p>
        </div>
      </div>
      <div className="project-description">
        <h3>Project Status</h3>
        <p>
          We are looking forward to working closely with the TWC team as we plan
          for future phases and bringing more universities onto the TWC
          platform.
        </p>
      </div>
    </TWCProjectStyles>
    <Footer />
  </main>
);

export default TSheetsChromeAppPage;
